import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '@wix/yoshi-flow-editor';

import type { IGroupMember, IMember } from 'api/members/types';
import { selectCurrentUser } from 'store/selectors';

import { PrivateProfileHint } from '../PrivateProfileHint';
import { Link } from '../Link';

interface IProps extends Partial<React.ComponentProps<typeof Link>> {
  profile?: IMember | IGroupMember;
}

export function ProfileLink(props: IProps) {
  const { profile, srefProps, children, ...rest } = props;

  const { t } = useTranslation();
  const user = useSelector(selectCurrentUser);

  const isPrivate = profile?.isPrivate && profile?.memberId !== user.memberId;

  return (
    <PrivateProfileHint disabled={!isPrivate}>
      <Link
        state="members.profile"
        disabled={!profile?.memberId}
        params={{ memberId: profile?.memberId }}
        target="_blank"
        {...rest}
      >
        {children || profile?.name || t('groups-web.member.anonymous')}
      </Link>
    </PrivateProfileHint>
  );
}

ProfileLink.displayName = 'ProfileLink';
