import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings, useStyles } from '@wix/tpa-settings/react';

import { BadgeList, BadgeType } from '@wix/members-badge-lib';

import type { IBadge } from 'api/badges/types';
import { useHttpClient } from 'common/hooks/useHttpClient';
import { Wire } from 'wui/Wire';

import * as paper from 'settings/wui/paper';
import * as common from 'settings/common';

import { MEMBER_BADGES_LIST } from './dataHook';

interface IProps {
  limit?: number;
  badges?: IBadge[];
}

export function MemberBadges(props: IProps) {
  const { limit, badges } = props;

  const settings = useSettings();
  const httpClient = useHttpClient();
  const styles = useStyles();
  const { i18n } = useTranslation();

  if (!badges?.length) {
    return null;
  }

  return (
    <Wire
      cssVarName="showMembersBadges"
      legacyFallback={settings.get(common.settings.showMembersBadges)}
    >
      <div data-hook={MEMBER_BADGES_LIST}>
        <BadgeList
          i18n={i18n}
          httpClient={httpClient}
          background
          font=""
          align="left"
          maxRows={1}
          maxBadges={limit}
          layout="name-icon"
          size="small"
          badges={badges as BadgeType[]}
          borderRadius={styles.get(paper.styles.paperBorderRadius)}
        />
      </div>
    </Wire>
  );
}

MemberBadges.displayName = 'MemberBadges';
MemberBadges.defaultProps = {
  limit: 2,
};
