import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { FormikHelpers, FormikProps } from 'formik';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';
import { groupSettingsSave } from '@wix/bi-logger-groups/v2';

import { selectGroup, selectIsGroupUpdating } from 'store/selectors';

import { useDidUpdate } from 'common/hooks';
import { useController } from 'common/context/controller';
import { EditGroupForm, IEditableGroup } from 'common/components/EditGroupForm';

import { Button } from 'wui/Button';
import { DialogContent } from 'wui/DialogContent';
import { DialogActions } from 'wui/DialogActions';

import { GROUP_SETTINGS_SAVE_BUTTON } from './dataHook';

interface IDetailsTabProps {
  groupId: string;

  onClose(): void;
}

export function DetailsTab(props: IDetailsTabProps) {
  const { groupId } = props;
  const { group$ } = useController();
  const { t } = useTranslation();

  const bi = useBi();

  const group = useSelector(selectGroup(groupId));
  const isUpdating = useSelector(selectIsGroupUpdating(groupId));

  const form = useRef<FormikProps<IEditableGroup>>(null);
  const [isBusy, setIsBusy] = React.useState(false);

  useDidUpdate(() => {
    if (!isUpdating) {
      props.onClose();
    }
  }, [isUpdating]);

  return (
    <>
      <DialogContent divider>
        <EditGroupForm
          innerRef={form}
          onSubmit={handleFormSubmit}
          onBusyStateChange={setIsBusy}
          initialValues={{
            name: group.name,
            privacyStatus: group.privacyStatus,
            coverImage: group.coverImage,
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button outlined variant="basic" fullWidth onClick={props.onClose}>
          {t('groups-web.cancel')}
        </Button>
        <Button
          fullWidth
          variant="basic"
          disabled={isBusy || isUpdating}
          loading={isBusy || isUpdating}
          onClick={handleSave}
          data-hook={GROUP_SETTINGS_SAVE_BUTTON}
        >
          {t('groups-web.save')}
        </Button>
      </DialogActions>
    </>
  );

  function handleSave() {
    form.current?.submitForm();
  }

  function handleFormSubmit(
    values: IEditableGroup,
    helpers: FormikHelpers<IEditableGroup>,
  ) {
    group$.updateGroupInfo(groupId, values);

    bi.report(
      groupSettingsSave({
        group_id: groupId,
        origin: 'save_button',
        tabName: 'details',
        type: values.privacyStatus,
        withPhoto: !!values.coverImage?.image,
        display_cover: true,
      }),
    );
  }
}

DetailsTab.displayName = 'DetailsTab';
